import { customHeaders } from '@/config/custom-headers';
import { GetTokenAsync, msApi, msApiPost } from '../clients';

export type MeeroSuiteRole = 'ms-content-manager' | 'ms-content-producer';

export type CreateUserInvitationDTO = {
  firstname: string;
  lastname: string;
  email: string;
  role: MeeroSuiteRole;
  phoneNumber?: string;
  locale?: string;
  countryCode?: string;
  reference?: string;
};

/**
 * POST /customer/user/invitations
 * Creates a UamUserInviation resource.
 */
export function createUserInvitation(
  body: CreateUserInvitationDTO,
  token: string | null,
  workspaceId: string | null
) {
  const response = msApi.post('customer/user/invitations', {
    headers: {
      Authorization: `Bearer ${token ?? 'missing_auth'}`,
      ...(workspaceId != null
        ? { [customHeaders.workspaceId]: workspaceId }
        : undefined),
    },
    json: body,
  });

  return response;
}

export interface InvitationDTO {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string | null;
  countryCode?: string | null;
  locale: string | null;
  role: 'ms-content-manager' | 'ms-content-producer';
  reference: string | null;
  sentAt: string;
}

/**
 * POST /customer/user/invitations/${invitationId}/resend
 * Resend an invitation to a content manager/producer
 */
export const resendInvitation = (
  invitationId: number,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  return msApiPost<InvitationDTO>(
    `customer/user/invitations/${invitationId}/resend`,
    getTokenAsync,
    workspaceId
  );
};

type AcceptInvitationDTO = {
  migrateData: boolean;
};

type InvitationDetails = {
  userId: string;
  invitationToken: string;
};

/**
 * POST /customer/user/{userIdentifier}/invitation/{token}/accept
 * Accept an invitation
 */
export const acceptInvitation = (
  { userId, invitationToken }: InvitationDetails,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  const json: AcceptInvitationDTO = {
    migrateData: true,
  };

  return msApiPost(
    `customer/user/usr|${userId}/invitation/${invitationToken}/accept`,
    getTokenAsync,
    workspaceId,
    {
      json,
      timeout: false,
    }
  );
};

/**
 * POST /customer/user/{userIdentifier}/invitation/{token}/decline
 * Decline an invitation
 */
export const declineInvitation = (
  { userId, invitationToken }: InvitationDetails,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  return msApiPost(
    `customer/user/usr|${userId}/invitation/${invitationToken}/decline`,
    getTokenAsync,
    workspaceId
  );
};
