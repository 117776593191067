import { Button as AriaButton } from 'react-aria-components';
import { Box } from '@mui/system';
import { Link } from 'react-aria-components';
import {
  colors,
  fontSize,
  fontWeight,
  getMeeSpacing,
  lineHeight,
} from '@/theme';
import styled, { css } from '@/utils/styled';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondary-white'
  | 'secondary-orange'
  | 'secondary-yellow'
  | 'tertiary';
export type ButtonProps = React.ComponentProps<typeof Button>;
export const Button = styled(AriaButton)<{
  $variant: ButtonVariant;
  $size?: 'small' | 'medium' | 'large';
  $fullWidth?: boolean;
}>`
  background-color: unset;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  font-weight: ${fontWeight.semiBold};

  ${({ $fullWidth = false }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}

  &[data-pressed] {
    background: ${colors.gramShair};
  }

  &[data-disabled] {
    cursor: not-allowed;
  }

  &[data-focus-visible] {
    outline: 2px solid ${colors.azrakBlue};
    outline-offset: -1px;
  }

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 32px;
          padding: ${getMeeSpacing(0.5, 1.5)};
          font-size: ${fontSize.small};
          line-height: ${lineHeight.xSmall};
        `;
      case 'medium':
        return css`
          height: 38px;
          padding: ${getMeeSpacing(1, 2)};
          font-size: ${fontSize.small};
          line-height: 20px;
        `;
      case 'large':
        return css`
          height: 40px;
          padding: ${getMeeSpacing(1.25, 2)};
          font-size: ${fontSize.medium};
          line-height: 20px;
        `;
      default:
        return undefined;
    }
  }}

  ${({ $variant }) => {
    switch ($variant) {
      case 'primary':
        return css`
          border: 1px solid ${colors.primary};
          background-color: ${colors.primary};
          color: ${colors.white};
          transition: background-color 0.2s ease;

          &[data-disabled] {
            background-color: ${colors.primary30};
            border: 1px solid ${colors.translucentWhite};
          }

          &[data-hovered] {
            background-color: ${colors.blueHour};
          }

          &[data-pressed] {
            background-color: ${colors.primary};
            box-shadow: inset 0 1px 2px rgb(0 0 0 / 0.1);
          }
        `;
      case 'secondary':
        return css`
          border: 1px solid ${colors.translucentWhite};
          background-color: ${colors.white};
          color: ${colors.infinity};

          &[data-disabled] {
            color: ${colors.miracle20};
            border-color: ${colors.miracle20};
          }

          &[data-hovered] {
            background-color: ${colors.whiteSmoke};
            color: ${colors.carbon};
          }
        `;

      case 'secondary-white':
        return css`
          border: 1px solid ${colors.white};
          color: ${colors.white};

          &[data-disabled] {
            color: ${colors.miracle20};
            border-color: ${colors.miracle20};
          }

          &[data-hovered] {
            background-color: ${colors.carbonAlpha10};
          }
        `;

      case 'secondary-orange':
        return css`
          background-color: ${colors.secondary};
          border: 1px solid ${colors.secondary};
          color: ${colors.white};

          &[data-disabled] {
            color: ${colors.secondary30};
            border-color: ${colors.secondary30};
          }

          &[data-hovered] {
            background-color: ${colors.coinSlot};
          }
        `;

      case 'secondary-yellow':
        return css`
          background-color: ${colors.annaBanana};
          border: none;

          &[data-disabled] {
            color: ${colors.infinity40};
            background-color: #f8d74acc;
          }

          &[data-hovered] {
            background-color: #fbea8c;
          }

          &[data-pressed] {
            background-color: #f6c629;
          }
        `;

      case 'tertiary':
        return css`
          border: none;
          color: ${colors.infinity};

          &[data-hovered] {
            background-color: ${colors.translucentWhite};
            color: ${colors.carbon};
          }
        `;
    }
  }}
`;

export const LinkButton = Button.withComponent(Link);

export const ButtonIconOnly = styled(Button)`
  display: flex;
  align-items: center;
  column-gap: ${getMeeSpacing(1)};
`;

export function ButtonWithIcon({
  icon,
  label,
  ...props
}: ButtonProps & { label: string; icon: JSX.Element }) {
  return (
    <Button {...props}>
      <Box
        display="flex"
        columnGap={1}
        alignItems="center"
        justifyContent="center"
        maxHeight="100%"
      >
        {icon}
        <span>{label}</span>
      </Box>
    </Button>
  );
}
