import { SnackbarProvider as NotistackProvider } from 'notistack';
import React from 'react';
import {
  ErrorIcon,
  InfoIcon,
  NotificationIcon,
  SuccessIcon,
  WarningIcon,
} from '@/components/common';
import {
  AnnouncementSnackbar,
  CustomSnackbar,
  SalesCampaignSnackbar,
} from '@/components/common/Snackbar/Snackbar';

export const SnackbarProvider = () => {
  return (
    <NotistackProvider
      iconVariant={{
        default: <NotificationIcon />,
        error: <ErrorIcon />,
        info: <InfoIcon />,
        success: <SuccessIcon />,
        warning: <WarningIcon />,
      }}
      Components={{
        default: CustomSnackbar,
        error: CustomSnackbar,
        info: CustomSnackbar,
        success: CustomSnackbar,
        warning: CustomSnackbar,
        announcement: AnnouncementSnackbar,
        salesCampaign: SalesCampaignSnackbar,
      }}
    />
  );
};

declare module 'notistack' {
  interface VariantOverrides {
    announcement: {
      title: string;
      subtitle: string;
      allowDismiss?: boolean;
    };
    salesCampaign: {
      title: string;
      ctaLabel: string;
      allowDismiss?: boolean;
    };
    default: {
      title?: string;
      allowDismiss?: boolean;
    };
    error: {
      title?: string;
      allowDismiss?: boolean;
    };
    info: {
      title?: string;
      allowDismiss?: boolean;
    };
    success: {
      title?: string;
      allowDismiss?: boolean;
    };
    warning: {
      title?: string;
      allowDismiss?: boolean;
    };
  }
}
