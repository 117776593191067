import {
  Key,
  MenuTrigger,
  Popover,
  RouterProvider,
  Separator,
  SubmenuTrigger,
} from 'react-aria-components';
import {
  KeyboardArrowRight,
  Language,
  PersonOutlined,
} from '@mui/icons-material';
import Logout from '@mui/icons-material/Logout';
import { Box, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';
import { useUserWorkspaceContext } from '@/context/UserWorkspaceContext';
import { getAppConfig } from '@/config/env';
import { Auth0User } from '@/api/auth0/user';
import { useCurrentMsUser } from '@/api/meero-suite/users';
import { trackButtonClicked } from '@/lib/analytics';
import { getSegmentPageLabel } from '@/lib/segment-label/page-label';
import { externalLinks } from '@/config/assets-links';
import { useWorskpaceInfo } from '@/api/meero-suite/watermark';
import { colors } from '@/theme';
import { StyledMenu, StyledMenuItem, StyledPopover } from '../BaseMenu.styles';
import { Button } from '../../Button/Button';
import {
  CheckBoxBlankIcon,
  CheckBoxFilledIcon,
  CheckCircleRoundedIcon,
  HelpCenterIcon,
  MoreOptionsIcon,
  StarOutlineIcon,
} from '../..';
import {
  MenuButtonContent,
  ProfileAriaMenu,
  ProfileMenuHeader,
  WorkspaceChip,
} from './ProfileMenu.styles';
import { TypographyEllipsis } from '../../TypographyEllipsis/TypographyEllipsis';

type ProfileMenuProps = { isMenuOpen: boolean };

export function ProfileMenu({ isMenuOpen }: ProfileMenuProps) {
  const router = useRouter();
  const { t } = useTranslation('navigationBar');
  const { allowExperimentalFeatures } = getAppConfig();

  const { msUser, isContentManager } = useCurrentMsUser();
  const { data: workspace } = useWorskpaceInfo();
  const { logout, user } = useAuth0<Auth0User>();
  const { toggleExperimentalFeatures, experimentalFeatures } =
    useUserWorkspaceContext();

  const showBillingMenu = isContentManager;

  const handleAction = (id: Key) => {
    switch (id) {
      case 'profile':
        trackButtonClicked('Profile Manage Profile Button Clicked', {
          page: getSegmentPageLabel(router.asPath, router.query),
        });
        break;
      case 'subscription':
        trackButtonClicked('Profile Subscription CTA Clicked', {
          page: getSegmentPageLabel(router.asPath, router.query),
        });
        break;
      case 'help-center':
        trackButtonClicked('Profile Help Center Button Clicked', {
          page: getSegmentPageLabel(router.asPath, router.query),
        });
        break;
      case 'logout':
        trackButtonClicked('Profile Logout Button Clicked', {
          page: getSegmentPageLabel(router.asPath, router.query),
        });
        void logout({
          logoutParams: { returnTo: `${window.location.origin}/welcome` },
        });
        break;
      case 'experimental-features':
        toggleExperimentalFeatures();
        break;
      case 'language-english':
        trackButtonClicked('Profile Language English Button Clicked', {
          page: getSegmentPageLabel(router.asPath, router.query),
        });
        Cookies.set('NEXT_LOCALE', 'en', { expires: 365 });
        void router.replace(router.asPath, undefined, { locale: 'en' });

        break;
      case 'language-french':
        trackButtonClicked('Profile Language French Button Clicked', {
          page: getSegmentPageLabel(router.asPath, router.query),
        });
        Cookies.set('NEXT_LOCALE', 'fr', { expires: 365 });
        void router.replace(router.asPath, undefined, { locale: 'fr' });

        break;
      default:
        break;
    }
  };

  return (
    <RouterProvider
      navigate={(href, opts) => void router.push(href, undefined, opts)}
    >
      <MenuTrigger>
        <Button $variant="tertiary">
          <MenuButtonContent>
            <Box display="flex" alignItems="center" columnGap={1}>
              <Box width={24} height={24}>
                <Avatar letter={msUser?.firstname.at(0) ?? '-'} />
              </Box>
              {isMenuOpen && (
                <Typography variant="body2" fontWeight={600}>
                  {msUser?.firstname}
                </Typography>
              )}
            </Box>
            {isMenuOpen && <MoreOptionsIcon fontSize="small" />}
          </MenuButtonContent>
        </Button>
        <StyledPopover placement="top" offset={8}>
          <ProfileMenuHeader>
            <WorkspaceChip title={workspace?.name}>
              {workspace?.name}
            </WorkspaceChip>
            <TypographyEllipsis
              variant="body2"
              fontWeight={600}
              marginTop={2}
              marginBottom={0.25}
            >
              {`${msUser?.firstname} ${msUser?.lastname}`}
            </TypographyEllipsis>
            <TypographyEllipsis fontSize={12} color="textSecondary">
              {user?.email}
            </TypographyEllipsis>
          </ProfileMenuHeader>
          <ProfileAriaMenu onAction={handleAction}>
            <StyledMenuItem id="profile" href="/profile">
              <PersonOutlined fontSize="small" />
              <span>{t('profileMenu.profile')}</span>
            </StyledMenuItem>
            <SubmenuTrigger>
              <StyledMenuItem id="language">
                <Language fontSize="small" />
                <Typography component="span" variant="body2" flexGrow={1}>
                  {t('profileMenu.language.title')}
                </Typography>
                <KeyboardArrowRight />
              </StyledMenuItem>
              <Popover>
                <StyledMenu onAction={handleAction}>
                  <StyledMenuItem id="language-english">
                    <Typography component="span" variant="body2" flexGrow={1}>
                      {t('profileMenu.language.english')}
                    </Typography>
                    {router.locale === 'en' && (
                      <CheckCircleRoundedIcon
                        sx={{ color: colors.secondary }}
                        fontSize="small"
                      />
                    )}
                  </StyledMenuItem>
                  <StyledMenuItem id="language-french">
                    <Typography component="span" variant="body2" flexGrow={1}>
                      {t('profileMenu.language.french')}
                    </Typography>
                    {router.locale === 'fr' && (
                      <CheckCircleRoundedIcon
                        sx={{ color: colors.secondary }}
                        fontSize="small"
                      />
                    )}
                  </StyledMenuItem>
                </StyledMenu>
              </Popover>
            </SubmenuTrigger>
            {showBillingMenu && (
              <StyledMenuItem id="subscription" href="/subscription">
                <StarOutlineIcon fontSize="small" />
                <span>{t('profileMenu.subscription')}</span>
              </StyledMenuItem>
            )}
            {allowExperimentalFeatures && (
              <StyledMenuItem id="experimental-features">
                {experimentalFeatures ? (
                  <CheckBoxFilledIcon fontSize="small" />
                ) : (
                  <CheckBoxBlankIcon fontSize="small" />
                )}
                <span>Experimental features</span>
              </StyledMenuItem>
            )}
            <Separator orientation="horizontal" />
            <StyledMenuItem
              id="help-center"
              href={externalLinks.helpCenterUrl}
              target="_blank"
            >
              <HelpCenterIcon fontSize="small" />
              <span>{t('profileMenu.helpCenter')}</span>
            </StyledMenuItem>
            <StyledMenuItem id="logout">
              <Logout fontSize="small" />
              <span>{t('profileMenu.logout')}</span>
            </StyledMenuItem>
          </ProfileAriaMenu>
        </StyledPopover>
      </MenuTrigger>
    </RouterProvider>
  );
}

function Avatar({ letter }: { letter: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" version="1.1">
      <circle fill="#FF6A34" width="64" height="64" cx="32" cy="32" r="32" />
      <text
        x="50%"
        y="50%"
        style={{ color: 'white' }}
        alignmentBaseline="middle"
        textAnchor="middle"
        fontSize="38"
        fontWeight="600"
        dy=".1em"
        dominantBaseline="middle"
        fill="#ffffff"
      >
        {letter}
      </text>
    </svg>
  );
}
