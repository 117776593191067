import { SnackbarContent } from 'notistack';
import styled from '@/utils/styled';
import { colors, getMeeSpacing } from '@/theme';
import { getTwicPicsUrl } from '@/lib/twicpics';

export const BaseSnackbar = styled(SnackbarContent)<{
  $backgroundColor: string;
  $fontColor: string;
}>`
  min-height: 56px;
  display: flex;
  padding: ${getMeeSpacing(1.5)};
  column-gap: ${getMeeSpacing(1.5)};
  border-radius: 12px;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $fontColor }) => $fontColor};
`;
const backgroundUrl = getTwicPicsUrl('webapp/quick_edits_illu.png?v=3', [
  'resize=600',
  'quality=100',
]);

export const AnnouncementSnackbarContent = styled(SnackbarContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(1)};
  padding: ${getMeeSpacing(3)};
  width: 360px;
  height: 260px;
  border-radius: 12px;
  background-color: ${colors.infinity};
  background-image: url(${backgroundUrl});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 160px;
  color: ${colors.white};

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const SalesCampaignSnackbarContent = styled(SnackbarContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: ${getMeeSpacing(0.5)};
  padding: ${getMeeSpacing(3)};
  width: 400px;
  height: 300px;
  border-radius: 12px;
  background-color: ${colors.cottonBoll};
  color: ${colors.primary};
  background-image: url('/images/Gift.svg'), url('/images/Gift.svg');
  background-repeat: no-repeat;
  background-size: 180px, 110px;
  background-position:
    bottom -50px right -50px,
    top -32px left -20px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const SalesMessageContainer = styled('div')`
  background-color: ${colors.white};
`;

export const DiscountContainer = styled('span')`
  color: ${colors.secondary};
  font-weight: 700;
`;

export const IconContainer = styled('div')<{
  $iconColor: string;
}>`
  color: ${({ $iconColor }) => $iconColor};
`;

export const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;
