import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useUamWorkspaces } from '@/api/uam';
import { useCurrentMsUser } from '@/api/meero-suite/users';
import { getSegmentUser, identifyUser, pageViewed } from '@/lib/analytics';
import { getSegmentPageLabel } from '@/lib/segment-label/page-label';
import { NavigationBar } from './NavigationBar/NavigationBar';
import { Main, Root } from './Navigation.styles';
import { useNavigationBarState } from './hooks/useNavbarState';
import { InvitationBanner } from './InvitationBanner/InvitationBanner';
import { useSalesCampaignSnackbar } from './hooks/useSalesCampaignSnackbar';

const EXEMPT_PAGES = [
  '/login',
  '/logout',
  '/health-check',
  '/send-reset-password',
  '/set-password',
  '/reset-password',
  '/reset-password/app-member',
  '/404',
  '/editor',
  '/signup',
  '/mobile-store-redirect',
];

type NavigationProps = {
  children?: ReactNode;
};

export const Navigation = ({ children }: NavigationProps) => {
  const router = useRouter();
  const { isNavBarOpen, toggleNavBar } = useNavigationBarState();
  const { isAuthenticated } = useAuth0();
  const { currentWorkspace } = useUamWorkspaces();
  const { msUser } = useCurrentMsUser();

  const [isBannerDismissed, setIsBannerDismissed] = useState(false);

  const isFullyAuthenticated =
    isAuthenticated && currentWorkspace !== undefined && msUser !== undefined;

  useSalesCampaignSnackbar();

  useEffect(() => {
    if (isFullyAuthenticated) {
      const segmentUser = getSegmentUser(msUser, currentWorkspace);
      identifyUser(segmentUser);
    }
  }, [isFullyAuthenticated, currentWorkspace, msUser]);

  useEffect(() => {
    if (isFullyAuthenticated) {
      const pageLabel = getSegmentPageLabel(router.asPath, router.query);

      const handleRouteChange = (
        url: string,
        { shallow }: { shallow: boolean }
      ) => {
        // Excluding shallow routing to avoid sending too many analytics request when the url params are updated
        if (!shallow && pageLabel !== undefined) {
          pageViewed(pageLabel);
        }
      };

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && pageLabel !== undefined) {
          pageViewed(pageLabel, { back_from_background: true });
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      };
    }
  }, [isFullyAuthenticated, router]);

  // Handling initial page view
  useEffect(() => {
    if (isFullyAuthenticated && typeof window !== 'undefined') {
      const pageLabel = getSegmentPageLabel(window.location.pathname, {});
      if (pageLabel !== undefined) {
        pageViewed(pageLabel);
      }
    }
  }, [isFullyAuthenticated]);

  /**x
   * Navigation components are not shown :
   * - On exempted pages (public pages mostly)
   * - When user is not authentified (should be showing a loading screen)
   */
  if (EXEMPT_PAGES.includes(router.pathname) || !isFullyAuthenticated) {
    return <Root>{children}</Root>;
  }

  return (
    <Root>
      <NavigationBar isOpen={isNavBarOpen} onToggle={toggleNavBar} />
      <Main $isOpen={isNavBarOpen}>
        {!isBannerDismissed && msUser.userInvitation !== null ? (
          <InvitationBanner
            invitation={msUser.userInvitation}
            onIgnoreBanner={() => setIsBannerDismissed(true)}
          />
        ) : null}
        {children}
      </Main>
    </Root>
  );
};
