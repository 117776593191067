import { createTheme } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { poppins, raleway } from './styles/fonts';

// https://colornamer.robertcooper.me/
// https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export const colors = {
  // primary color (Meero)
  primary: '#042387',
  primary30: '#0423874D',
  secondary: '#FF6A34',
  secondary30: '#FF6A344D',
  // font colors
  infinity: '#232631',
  infinity40: '#23263166',
  miracle: '#898896',
  miracle20: '#89889633',
  miracle50: '#89889680',
  // grey
  translucentWhite: '#E3E3E8',
  crystalBell: '#F1F3FA',
  whiteSmoke: '#F5F5F5',
  hydrargyrum: '#9B9B9B',
  carbon: '#333333',
  carbonAlpha10: '#3333331A',
  carbonAlpha80: '#333333CC',
  // error (main and dark)
  manganeseRed: '#E42654',
  manganeseRed50: '#E4265480',
  ruralRed: '#BB0A3F',
  cherubic: '#FEE6F2',
  // warning
  dodgerollGold: '#F29A17',
  copiousCaramel: '#D2891E',
  fatBack: '#FEF7EC',
  // info
  epicBlue: '#0566EB',
  amnesiaBlue: '#0F5DC7',
  cottonBoll: '#E5EFFD',
  // success
  chromophobiaGreen: '#0EAA85',
  hollyGreen: '#169C7C',
  frostyMint: '#E2F5F0',
  // background
  white: '#FFFFFF',
  gramShair: '#F9FBFE',
  moonWhite: '#D6E7FF',
  youngNight: '#232323',
  orangeLight: '#FFF4F0',
  // other
  blueHour: '#062EB2',
  azrakBlue: '#4561BA',
  eyePatch: '#232121',
  blackAlpha50: '#00000080',
  ohPistachio: '#F8D74A29',
  annaBanana: '#f8d74a',
  coinSlot: '#fe4611',
} as const;

export const spaces = {
  heightHeader: '68px',
  smallGlobalSpace: '32px',
} as const;

export const breakpoints: typeof theme.breakpoints.values = {
  xs: 0,
  sm: 550,
  md: 950,
  lg: 1128,
  xl: 1640,
  xxl: 1880,
} as const;
export type Device = keyof typeof breakpoints;

export const fontWeight = {
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
} as const;

export const fontSize = {
  xxSmall: '10px',
  xSmall: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
  xLarge: '24px',
  xxLarge: '26px',
  xxxLarge: '32px',
} as const;

export const lineHeight = {
  xxSmall: '12px',
  xSmaller: '16px',
  xSmall: '18px',
  small: '22px',
  medium: '24px',
  mediumLarge: '26px',
  large: '28px',
  xLarge: '32px',
  xxLarge: '40px',
} as const;

export const fonts = {
  primary: poppins.style.fontFamily, // always make sure it is in sync with the font-family in globals.css
  secondary: raleway.style.fontFamily,
};

const MEE_SPACING_PX = 8;

/**
 * Mui theme module augmentation
 */
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }
  interface Palette {
    default: Palette['primary'];
  }

  interface PaletteOptions {
    default: PaletteOptions['primary'];
  }

  interface TypographyVariantsOptions {
    description?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    description: true;
  }
}

const theme = createTheme({
  spacing: MEE_SPACING_PX,
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.annaBanana,
    },
    text: {
      primary: colors.infinity,
      secondary: colors.miracle,
      disabled: colors.translucentWhite,
    },
    default: {
      main: colors.miracle,
      dark: colors.infinity,
      light: colors.translucentWhite,
    },
    error: {
      main: colors.manganeseRed,
      dark: colors.ruralRed,
      light: colors.cherubic,
    },
    warning: {
      main: colors.dodgerollGold,
      dark: colors.copiousCaramel,
      light: colors.fatBack,
    },
    info: {
      main: colors.epicBlue,
      dark: colors.amnesiaBlue,
      light: colors.cottonBoll,
    },
    success: {
      main: colors.chromophobiaGreen,
      dark: colors.hollyGreen,
      light: colors.frostyMint,
    },
    background: {
      default: colors.white,
      paper: colors.gramShair,
    },
  },
  typography: {
    fontFamily: fonts.primary,
    h1: {
      fontFamily: fonts.secondary,
      fontWeight: fontWeight.bold,
      fontSize: 64,
      lineHeight: '76px',
    },
    h2: {
      fontFamily: fonts.secondary,
      fontWeight: fontWeight.bold,
      fontSize: 54,
      lineHeight: '64px',
    },
    h3: {
      fontFamily: fonts.secondary,
      fontWeight: fontWeight.bold,
      fontSize: 44,
      lineHeight: '54px',
    },
    h4: {
      fontFamily: fonts.secondary,
      fontWeight: fontWeight.bold,
      fontSize: fontSize.xxxLarge,
      lineHeight: lineHeight.xxLarge,
    },
    h5: {
      fontFamily: fonts.secondary,
      fontWeight: fontWeight.bold,
      fontSize: fontSize.xLarge,
      lineHeight: lineHeight.xLarge,
    },
    h6: {
      fontFamily: fonts.secondary,
      fontWeight: fontWeight.bold,
      fontSize: fontSize.small,
      lineHeight: lineHeight.xSmall,
      textTransform: 'uppercase',
      letterSpacing: 0.5,
    },
    subtitle1: {
      fontSize: fontSize.large,
      lineHeight: lineHeight.medium,
    },
    subtitle2: {
      fontSize: fontSize.medium,
      lineHeight: lineHeight.medium,
    },
    body1: {
      fontSize: fontSize.medium,
      lineHeight: lineHeight.medium,
    },
    body2: {
      fontSize: fontSize.small,
      lineHeight: lineHeight.xSmall,
    },
    caption: {
      fontSize: fontSize.xSmall,
      fontWeight: fontWeight.semiBold,
      lineHeight: lineHeight.xSmaller,
    },
    description: {
      fontFamily: fonts.primary,
      fontSize: fontSize.medium,
      lineHeight: lineHeight.medium,
      color: colors.hydrargyrum,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          description: 'div',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: fontWeight.normal,
          marginTop: 4,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: colors.gramShair,
          },
        },
        input: {
          '&.Mui-disabled': {
            color: colors.miracle,
            WebkitTextFillColor: colors.miracle,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            height: 40,
            minWidth: 40,
            borderRadius: 20,
          },
          '&.Mui-selected': {
            backgroundColor: colors.moonWhite,
            color: colors.primary,
            fontWeight: fontWeight.semiBold,
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.moonWhite,
          },
          '&:hover': {
            backgroundColor: colors.gramShair,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'unset',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 0 8px 0',
          minHeight: 0,
        },
        content: {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: colors.translucentWhite,
          [`&:hover:not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: colors.translucentWhite,
          },
          [`&.Mui-focused:not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: colors.primary,
            },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  mixins: {
    toolbar: {
      minHeight: spaces.heightHeader,
    },
  },
  zIndex: {
    drawer: 0,
  },
});
export const getMeeSpacing = theme.spacing;

export default theme;
