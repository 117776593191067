import { MarkEmailUnreadOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { enqueueSnackbar } from 'notistack';
import { Button, ButtonIconOnly } from '@/components/common/Button/Button';
import { Box, CloseIcon } from '@/components/common';
import { useDialog } from '@/context/DialogContext';
import { useCurrentMsUser, UserInvitationDTO } from '@/api/meero-suite/users';
import {
  acceptInvitation,
  declineInvitation,
} from '@/api/meero-suite/users-invitations';
import { useAuth0Credentials } from '@/hooks/use-auth0-credentials';
import { WorkspaceSwitchModal } from '@/components/common/WorkspaceSwitchModal/WorkspaceSwitchModal';
import { BannerContainer } from './InvitationBanner.styles';

type InvitationBannerProps = {
  invitation: UserInvitationDTO;
  onIgnoreBanner: () => void;
};

const MIGRATION_DURATION_MIN = 8000;

export function InvitationBanner({
  invitation,
  onIgnoreBanner,
}: InvitationBannerProps) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { userId, workspaceId, getTokenAsync } = useAuth0Credentials();
  const { openDialog, dismissDialog } = useDialog();

  const { mutateUser } = useCurrentMsUser();

  const [showMigrationModal, setShowMigrationModal] = useState(false);

  const handleOpenDialog = () => {
    openDialog({
      title: t('invitation.dialog.title', {
        workspaceName: invitation.accountName,
      }),
      message: t('invitation.dialog.message', {
        senderEmail: invitation.invitedByEmail,
        senderName: `${invitation.invitedByFirstname} ${invitation.invitedByLastname}`,
        workspaceName: invitation.accountName,
      }),
      confirmButtonLabel: t('invitation.dialog.accept'),
      onConfirm: () => {
        if (userId === null) return;

        setShowMigrationModal(true);

        const start = Date.now();
        void acceptInvitation(
          {
            userId,
            invitationToken: invitation.token,
          },
          getTokenAsync,
          workspaceId
        )
          .catch(() => {
            enqueueSnackbar({
              variant: 'error',
              title: t('invitation.accept.error.title'),
              message: t('invitation.accept.error.message'),
            });

            return Promise.reject();
          })
          .then(() => {
            enqueueSnackbar({
              variant: 'success',
              persist: true,
              title: t('invitation.accept.success.title', {
                workspaceName: invitation.accountName,
              }),
              message: t('invitation.accept.success.message'),
            });

            const duration = Date.now() - start;
            // Ensure the migration modal is displayed for at least X seconds
            if (duration < MIGRATION_DURATION_MIN) {
              return new Promise((resolve) => {
                setTimeout(resolve, MIGRATION_DURATION_MIN - duration);
              });
            }

            return Promise.resolve();
          })
          .then(() => {
            router.reload();
          })
          .finally(() => {
            setShowMigrationModal(false);
            dismissDialog();
          });
      },
      dismissButtonLabel: t('invitation.dialog.decline'),
      onDismiss: () => {
        if (userId === null) return;
        void declineInvitation(
          {
            userId,
            invitationToken: invitation.token,
          },
          getTokenAsync,
          workspaceId
        )
          .catch(() => {
            enqueueSnackbar({
              variant: 'error',
              title: t('invitation.decline.error.title'),
            });

            return Promise.reject();
          })
          .then(() => {
            enqueueSnackbar({
              variant: 'success',
              title: t('invitation.decline.success.title'),
            });
            void mutateUser((prev) =>
              prev
                ? {
                    ...prev,
                    userInvitation: null,
                  }
                : undefined
            );
            dismissDialog();
          });
      },
      onClose: dismissDialog,
    });
  };

  return (
    <>
      <WorkspaceSwitchModal
        isOpen={showMigrationModal}
        newWorkspaceName={invitation.accountName}
      />
      <BannerContainer>
        <Box component="span" display="flex" gap={1} color="white">
          <MarkEmailUnreadOutlined />
          <Typography variant="body1" color="white" fontWeight={600}>
            {t('invitation.banner.title')}
          </Typography>
        </Box>
        <Box
          component="span"
          position="absolute"
          right={16}
          display="flex"
          gap={1}
          color="white"
          alignItems="center"
        >
          <Button $variant="secondary-white" onPress={handleOpenDialog}>
            <Typography
              variant="body1"
              color="white"
              lineHeight="24px"
              paddingX={3}
              fontWeight={600}
            >
              {t('invitation.banner.view')}
            </Typography>
          </Button>
          <ButtonIconOnly
            $variant="secondary-white"
            style={{ border: 'none' }}
            onPress={onIgnoreBanner}
          >
            <Box height={24} display="flex" alignItems="center">
              <CloseIcon fontSize="small" color="inherit" />
            </Box>
          </ButtonIconOnly>
        </Box>
      </BannerContainer>
    </>
  );
}
