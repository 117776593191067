import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';
import {
  CalendarDate,
  getLocalTimeZone,
  parseDate,
  today,
} from '@internationalized/date';
import { useLocalStorageValue } from '@react-hookz/web';
import { useLicence } from '@/api/meero-suite/billing';

const CAMPAIGN_END_DATE = new CalendarDate(2024, 10, 15);
const SHOW_AGAIN_AFTER_DAYS = 2;

export function isSalesCampaignActive() {
  const localDate = today(getLocalTimeZone());

  return localDate.compare(CAMPAIGN_END_DATE) <= 0;
}

/**
 * Show the sales campaign snackbar to users who are not premium and have not dismissed it in a given time frame
 **/
export function useSalesCampaignSnackbar() {
  const { t } = useTranslation('common');
  const { data: licence } = useLicence();

  const { value: dismissedDate, set: setDismissedDate } = useLocalStorageValue<
    CalendarDate | null,
    null,
    true
  >('sales-campaign-dismissed', {
    parse: (value) => (value !== null ? parseDate(value) : null),
    stringify: (value) => (value !== null ? value.toString() : null),
  });

  useEffect(() => {
    const localDate = today(getLocalTimeZone());

    const canShowAgain =
      dismissedDate === null ||
      localDate.compare(dismissedDate) >= SHOW_AGAIN_AFTER_DAYS;

    if (
      isSalesCampaignActive() &&
      licence?.computed.isPremium === false &&
      canShowAgain
    ) {
      enqueueSnackbar({
        variant: 'salesCampaign',
        title: t('snackbars.salesCampaign.title'),
        message: t('snackbars.salesCampaign.message'),
        ctaLabel: t('snackbars.salesCampaign.cta'),
        persist: true,
        preventDuplicate: true,
        onClose: () => {
          setDismissedDate(localDate);
        },
      });
    }
  }, [t, licence?.computed.isPremium, dismissedDate, setDismissedDate]);
}
