// If the version has a point, it means it's a release version and not a commit hash
export const isHashCommit = (version: string) => {
  return !version.includes('.');
};

// Returns a Map with a correctly typed value
export function makeMap<V = unknown>(obj: Record<string, V>) {
  return new Map<string, V>(Object.entries(obj));
}

// check if an object has some defined properties
export function hasDefinedProperties<T extends object>(obj: T) {
  return Object.keys(obj).some((key) => obj[key as keyof T] !== undefined);
}
