import { useRouter } from 'next/router';
import Link from 'next/link';
import { Fade, Typography, WarningFilled } from '@/components/common';
import { ButtonMeero } from '@/components/common/ButtonMeero/ButtonMeero';
import { StarIcon } from '@/components/common';
import { trackButtonClicked } from '@/lib/analytics';
import { getSegmentPageLabel } from '@/lib/segment-label/page-label';
import ProperShotIcon from '@/components/icons/ProperShotIcon';
import { LicenceStatus, useWorkspaceCredits } from '@/api/meero-suite/billing';
import PremiumIcon from '@/components/icons/PremiumIcon';
import { TypographyEllipsis } from '@/components/common/TypographyEllipsis/TypographyEllipsis';
import { getAppConfig } from '@/config/env';
import {
  BannerContainer,
  BannerHeader,
  FreeTrialIndicator,
  PropershotIconContainer,
  WorkspacePropertyCredits,
} from './LicenceBanner.styles';
import { StatusTag } from './LicenceBanner.styles';
import { useLicenceBanner } from './useLicenceBanner';

interface LicenceBannerProps {
  isOpen: boolean;
}
export function LicenceBanner({ isOpen }: LicenceBannerProps) {
  const { asPath, query } = useRouter();

  const banner = useLicenceBanner();
  const { enableBillingCredits } = getAppConfig();
  const { data: credits } = useWorkspaceCredits(enableBillingCredits);

  const creditsLeft = credits?.propertyCredit;

  if (banner === undefined) return null;

  const { status, statusLabel, message, showCta, ctaLabel } = banner;

  return (
    <>
      <Fade in={isOpen} appear={isOpen} timeout={{ enter: 1500 }}>
        <BannerContainer $isMenuOpen={isOpen}>
          <BannerHeader>
            <WorkspacePropertyCredits>
              <PropershotIconContainer $hasNoCredit={creditsLeft === 0}>
                <ProperShotIcon fill="white" />
              </PropershotIconContainer>
              {enableBillingCredits && creditsLeft !== undefined ? (
                <Typography fontFamily="inherit" variant="h5">
                  {creditsLeft}
                </Typography>
              ) : null}
            </WorkspacePropertyCredits>
            <SubscriptionStatusTag status={status} label={statusLabel} />
          </BannerHeader>
          <Typography variant="body2" color="textSecondary">
            {message}
          </Typography>
          {showCta ? (
            <Link href="/subscription">
              <ButtonMeero
                fullWidth
                onClick={() =>
                  trackButtonClicked(
                    status === 'trialing'
                      ? 'Get Premium Access Now CTA Clicked'
                      : 'Free Trial Over Subscribe CTA Clicked',
                    {
                      page: getSegmentPageLabel(asPath, query),
                    }
                  )
                }
              >
                {ctaLabel}
              </ButtonMeero>
            </Link>
          ) : null}
        </BannerContainer>
      </Fade>
      {showCta ? (
        <Link href="/subscription">
          <Fade in={!isOpen} appear={isOpen} timeout={{ enter: 350 }}>
            <FreeTrialIndicator
              $isMenuOpen={isOpen}
              onClick={() =>
                trackButtonClicked('Subscribe CTA Clicked', {
                  page: getSegmentPageLabel(asPath, query),
                })
              }
            >
              <StarIcon fontSize="small" />
            </FreeTrialIndicator>
          </Fade>
        </Link>
      ) : null}
    </>
  );
}

type SubscriptionStatusTagProps = {
  status: LicenceStatus;
  label: string;
};

const StatusToIcon: Record<LicenceStatus, React.ReactNode> = {
  active: <PremiumIcon fontSize="inherit" />,
  'active-but-cancelled': <WarningFilled fontSize="inherit" />,
  trialing: null,
  'trial-expired': null,
  inactive: null,
};

function SubscriptionStatusTag({ status, label }: SubscriptionStatusTagProps) {
  return (
    <StatusTag data-status={status}>
      {StatusToIcon[status]}
      <TypographyEllipsis component="span" fontSize={12} fontWeight={600}>
        {label}
      </TypographyEllipsis>
    </StatusTag>
  );
}
